
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Chart from '../Charts/Chart.vue';
import { abbreviateNumber, formatPercentage } from '@/utils/number';
import { isEmpty, isNil } from '@/utils/misc';
import { DataWithTimeSpan } from '@/models/Charts/chartsData';
import { AbstractKpiWidgetGenerator } from '@/models/Charts/abstract/abstractKpiWidgetGenerator';
import ValueTrend from '@/components/common/ValueTrend.vue';

@Component({
  components: {
    ValueTrend,
    Chart,
  },
  methods: {
    abbreviateNumber,
    formatPercentage,
    isEmpty,
    isNil,
  },
})
export default class KpiWidget extends Vue {
  @Prop({ required: true })
  private data!: DataWithTimeSpan[] | null;

  @Prop({ required: true })
  private generator!: AbstractKpiWidgetGenerator<any>;

  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true, type: Boolean })
  private loading!: boolean;

  @Prop({ required: true, type: Boolean })
  private isFetchingForTheFirstTime!: boolean;

  @Prop({ required: true })
  private mappingFunction!: (entry: DataWithTimeSpan) => number;

  private getTotalAmount(isPrimary: boolean) {
    return this.data!.filter((x) => x.isprimary === (isPrimary ? 1 : 0))
      .map(this.mappingFunction)
      .reduce((a, b) => a + b, 0);
  }

  private get formattedMainFact() {
    return this.generator.formatWithUnit(abbreviateNumber(this.primaryTotalAmount));
  }

  private get noData() {
    return isNil(this.data) || isEmpty(this.data) || !this.data.some((x) => x.isprimary);
  }

  private get isThereTrendInfo() {
    return this.data!.some((x) => x.isprimary === 0);
  }

  private get primaryTotalAmount() {
    return this.getTotalAmount(true);
  }

  private get previousTotalAmount() {
    return this.getTotalAmount(false);
  }
}
